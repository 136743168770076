/** @jsx jsx */

import { jsx, Container } from "theme-ui"

// import Link from "@components/link"

const Footer = ({ location, title, children, ...rest }) => (
  <Container p={4} bg="muted" {...rest}>
    <div>
      {/* <Styled.a
        sx={{
          variant: "styles.navlink",
          pr: 2,
        }}
        href="#"
      >
        Back to top
      </Styled.a> */}
      {/* <Styled.a
        sx={{
          variant: "styles.navlink",
          px: 2,
        }}
        href="mailto:mail@ryenbeaty.info"
      >
        Email
      </Styled.a> */}
      {/* <Styled.a
        sx={{
          variant: "styles.navlink",
          px: 2,
        }}
        href="https://buttondown.email/ryenbeatty"
      >
        Newsletter
      </Styled.a> */}
      {/* <Link
        to="/rss.xml"
        sx={{
          variant: "styles.navlink",
          px: 2,
        }}
      >
        Feed
      </Link> */}
    </div>
    <div sx={{ pt: 2 }}>© 2010-∞</div>
  </Container>
)

export default Footer
