/** @jsx jsx */

import { jsx, Container } from "theme-ui"

import Header from "@components/header"
import Footer from "@components/footer"

const RootLayout = ({ location, title, children }) => {
  return (
    <section
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        variant: "layout.root",
      }}
    >
      {/* <Header
        title={title}
        sx={{
          width: "100%",
          variant: "layout.header",

          display: "grid",
          gridGap: 4, // theme.space[4]
          gridTemplateColumns: [
            "380px auto", // default to a stacked layout on small screens
          ],
        }}
      /> */}

      <main
        sx={{
          width: "100%",
          flex: "1 1 auto",
        }}
      >
        <Container p={4} bg="muted">
          {children}
        </Container>
      </main>

      <Footer
        sx={{
          width: "100%",
          variant: "layout.footer",
        }}
      />
    </section>
  )
}

export default RootLayout
